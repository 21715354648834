@use "../mixins/mq";

.c-two_columns__item {
		margin-block: 4rem;

		@include mq.mq($from: large) {
			margin-block: 8rem;
		}

		&[data-item-1]{
			margin-top: 2rem;			

			@include mq.mq($from: large) {
				margin-top: 4rem;
			}
		}

	> div {
		height: 100%;
		padding: 0;
		display: flex;
		align-items: center;
	}
}

.c-two_columns__title {
	padding-left: 5px;
	margin-bottom: 3.1rem;
}

.c-two_columns__item-text {
	padding-top: 3rem;

	@include mq.mq($from: large) {
		padding-bottom: 3rem;

		.row--reverse & {
			padding-left: 0;
		}
	}
}

.c-text_bild .container {
	
	.c-two_columns__item-image .figure {
		overflow: hidden;
		border-radius: 20px;
		max-height: 320px;
		height: 100%;
		padding-bottom: 0;
	}
}

@include mq.mq($until: large) {
	.c-two_columns__item-text {
		order: 1;

		.item-text--wrapper {
			margin-bottom: 2.4rem;
		}
	}
	.c-two_columns__item-image {
		order: 2;
	}
}
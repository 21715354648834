@use "../mixins/mq";
@use "../_base/typography";

:root {
    --news-wrapper-padding: 16px;
} 

.news {
    background-color: var(--color-success);
    padding: var(--news-wrapper-padding);
    height: 40rem;

        @include mq.mq($from: large) {
            --news-wrapper-padding: 36px;
            padding-left: 34px;
            padding-right: 34px;
            height: 80rem;
        }

    .figure-fullscreen:after {
        content: unset;
    }

    .img-fullscreen {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text {
        position: absolute;
        bottom: 35px;
        left: 38px;
        width: calc(100% - 76px);

        @include mq.mq($from: large) {
            bottom: 80px;
            left: 80px;
            width: calc(100% - 160px);
        }

    }

    &__title {
        @extend .h4;

        @include mq.mq($from: large) {
            font-size: var(--font-size-h1);
        }
      
    }
    
    .tile__overlay {
        --tile-overlay-margin: 0;
    }
}